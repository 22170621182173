import React from "react";
import Footer from "../home/Footer";
import NavigationBar from "./../home/NavigationBar";
import diamond_bg_img from "./../../assets/imgs/about_heading.jpg";
import card_one from "./../../assets/svgs/card_one.svg";
import card_two from "./../../assets/svgs/card_two.svg";
import card_three from "./../../assets/svgs/card_three.svg";

function AboutUs() {
  const cards = [
    {
      title: "Free Shipping",
      description:
        "The free shipping feature makes us stand out from our competitors. We offer reliable EXPRESS delivery to our clients.",
      imageSrc: card_one,
    },
    {
      title: "Safe Payments",
      description:
        "The interface allows clients to execute safe and transparent payments. One can choose diamonds from multiple suppliers.",
      imageSrc: card_two,
    },
    {
      title: "Support Online",
      description:
        "You can access our LIVE inventory to scroll through the entire diamond collection before picking out your favorite piece.",
      imageSrc: card_three,
    },
  ];

  const aboutUsCounts = [
    {
      number: "01",
      title: "Guarantee",
      content:
        "Our clients are like our family. We work on principles and follow business ethics religiously. All the diamond pieces showcased on our website pass through several quality checks so that the clients purchasing them get the best product at the best price. Our supplier network is very strong and reliable as we keep quality over everything else. The products offered on The Transectra website come with a guaranteed assurance of quality and cost.",
    },
    {
      number: "02",
      title: "Quality",
      content:
        "The diamonds showcased on our platform constitute the best collection in the diamond industry. Each piece is delivered to the purchaser after conducting multiple quality checks by experts and professionals. The users get access to quality certificates related to each diamond piece available on the portal. Our man-made diamond collection has gained a lot of popularity in the sector because of its high quality standards.",
    },
    {
      number: "03",
      title: "Experience",
      content:
        "We have a dedicated team of hard-working professionals who strive to offer the best-in-class services to the clients. The long experience possessed by the team allows them to provide reliable guidance and support to the purchasers, suppliers, etc. The Transectra manages everything from purchasing to shipping and door-to-door insurance for the customers.",
    },
    {
      number: "04",
      title: "Professionality",
      content:
        "Our core infrastructure is designed to suit the needs of every person visiting our website. We are thorough professionals when it comes to working. You can enjoy seamless access to a high-quality diamond collection on our portal and then be rest assured of the quality checks, delivery, and shipping because of our professional team.",
    },
  ];

  return (
    <>
      <NavigationBar />
      {/* Start: About us */}
      {/* <div className='tr__aboutus-bg'>
                <div className='container'>
                    <div className='tr__about_us_main'>
                        <div className='tr__aboutus_heading'>
                            <div className='border-content'></div>
                            <h4>About Us</h4>
                        </div>
                        <h2>Best place to get a diamonds</h2>
                        <p>With perseverance, dedication, and expertise, The Transectra is on the path to becoming one of the largest B2B online channels for listing certified diamonds. We work round the clock to offer a trusted marketspace for the jewelry industry where we build professional connections between purchasers and suppliers.</p>
                        <p>With our B2B marketplace for diamonds to serve clients irrespective of their business size or location, expand their profits, and open new doors of success. We have a huge portfolio of natural diamonds and lab-grown diamonds from trusted suppliers.</p>
                    </div>
                </div>
            </div> */}
      <div className="tr__aboutus-bg">
        <div className="tr__aboutus_heading">
          <div className="border-content"></div>
          <h4>About Us</h4>
        </div>
        <div className="container">
          <div className=" flex-md-column flex-column flex-lg-row d-flex align-items-center gap-aboutus">
            <div className="tr__aboutus_img_container">
              <img src={diamond_bg_img} alt="img" />
            </div>
            <div className="tr__about_us_main">
              <div className="tr__aboutus_subheading">
                <div className="border-content"></div>
                <h2>Best place to get a diamonds</h2>
              </div>
              <p>
                With perseverance, dedication, and expertise, The Transectra is
                on the path to becoming one of the largest B2B online channels
                for listing certified diamonds. We work round the clock to offer
                a trusted marketspace for the jewelry industry where we build
                professional connections between purchasers and suppliers.
              </p>
              <p>
                With our B2B marketplace for diamonds to serve clients
                irrespective of their business size or location, expand their
                profits, and open new doors of success. We have a huge portfolio
                of natural diamonds and lab-grown diamonds from trusted
                suppliers.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------- */}
      <div className="container">
        <div className="tr__counts_heading_port">
          <h2>The Transectra</h2>
          <div className="border-content"></div>
        </div>
        {aboutUsCounts.map((item, index) => (
          <div className="tr_aboutus_counts_main" key={index}>
            <div className="tr_aboutus_count_h1">
              <h1>{item.number}</h1>
            </div>
            <div className="tr_aboutus_count_content">
              <h5>{item.title}</h5>
              <p>{item.content}</p>
            </div>
          </div>
        ))}
      </div>
      {/*  */}
      <div className="container">
        <div className="tr__counts_heading_port d-flex flex-column align-items-center justify-content-center max-w-max">
          <h2>Exclusive Features</h2>
          <div className="border-content"></div>
        </div>
        {/* cards design */}
        <div className="row px-3 px-md-0 tr__gaping_add justify-content-md-center justify-content-lg-start tr_extra_b_margin">
          {cards.map((card, index) => (
            <div className="card_wrapper_main col-lg col-md-8" key={index}>
              <div className="card_img_wrapper">
                <img src={card.imageSrc} alt="img" />
              </div>
              <h5>{card.title}</h5>
              <p>{card.description}</p>
            </div>
          ))}
        </div>
      </div>
      {/* End: About us */}
      <Footer />
    </>
  );
}

export default AboutUs;
