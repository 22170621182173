import menuIcon from "../../assets/svgs/menubar.svg";

// top menu
// import flag from "../../assets/svgs/flag.svg";
import droparrow from "../../assets/svgs/arrow-down.svg";
import navlogo from "../../assets/svgs/nav-logo.svg";

// sidebar
import logo from "../../assets/svgs/logo1.svg";
import dashboard from "../../assets/svgs/dash.svg";
import pair from "../../assets/svgs/pair-dash.svg";
import quick from "../../assets/svgs/quick-dash.svg";
import searchdash from "../../assets/svgs/search-dash.svg";
import cart from "../../assets/svgs/cart-dash.svg";
import watch from "../../assets/svgs/watch-dash.svg";
import order from "../../assets/svgs/order-dash.svg";
import recent from "../../assets/svgs/recent-dash.svg";
import save from "../../assets/svgs/save-dash.svg";
import logoutdash from "../../assets/svgs/logout-dash.svg";
import support from "../../assets/svgs/support-dash.svg";

// topbar
import bell from "../../assets/svgs/bell.svg";
import calc from "../../assets/svgs/calc.svg";
import flag from "../../assets/svgs/flag.svg";
import dropDownarrow from "../../assets/svgs/drop-arrow.svg";
import search from "../../assets/svgs/search.svg";

// dashboard
import wishlist from "../../assets/svgs/wish-slide.svg";
import cartlist from "../../assets/svgs/cart-slide.svg";
import wtsp from "../../assets/svgs/wtsp.svg";
import skype from "../../assets/svgs/skype.svg";
import call from "../../assets/svgs/call.svg";
import mail from "../../assets/svgs/mail.svg";

// pricing
import cancel from "../../assets/svgs/cancel.svg";
import right from "../../assets/svgs/right.svg";

import close from "../../assets/svgs/close.svg";

// profile drop

import profile from "../../assets/svgs/profile.svg";
import settings from "../../assets/svgs/settings.svg";
import help from "../../assets/svgs/help.svg";
import security from "../../assets/svgs/pas&sec.svg";
import permission from "../../assets/svgs/permission.svg";
import purchse from "../../assets/svgs/purchase.svg";
import terms from "../../assets/svgs/terms.svg";

// calculator
import calcDimond from "../../assets/svgs/calc-dimond.svg";
import filter from "../../assets/svgs/calculator/filter.svg";
import backBtn from "../../assets/svgs/calculator/back.svg";
import cartIcon from "../../assets/svgs/calculator/cart.svg";
import shoppingBag from "../../assets/svgs/calculator/shopping-bag.svg";
import sortingIcon from "../../assets/svgs/calculator/sorting.svg";
import addIcon from "../../assets/svgs/calculator/add.svg";
import msgBox from "../../assets/svgs/calculator/msgbox.svg";
import lockIcon from "../../assets/svgs/calculator/lock.svg";
import dollarDimond from "../../assets/svgs/calculator/dimond-dollar.svg";
import refreshIcon from "../../assets/svgs/calculator/refresh.svg";
import showEye from "../../assets/svgs/calculator/show.svg";
import exportIcon from "../../assets/svgs/calculator/xport.svg";

import gridView from "../../assets/svgs/calculator/gridview.svg";
import listView from "../../assets/svgs/calculator/listview.svg";

// trade
import location from "../../assets/svgs/trade/location.svg";
import tradePrice from "../../assets/svgs/trade/trade-price.svg";

import saveSearch from "../../assets/svgs/save.svg";
import searchWhite from "../../assets/svgs/white-search.svg";

// shape
import round from "../../assets/svgs/dimondShape/round.svg";
import oval from "../../assets/svgs/dimondShape/oval.svg";
import princess from "../../assets/svgs/dimondShape/princess.svg";
import pear from "../../assets/svgs/dimondShape/pear.svg";
import heart from "../../assets/svgs/dimondShape/heart.svg";
import rose from "../../assets/svgs/dimondShape/rose.svg";
import emarald from "../../assets/svgs/dimondShape/emerald.svg";
import murquise from "../../assets/svgs/dimondShape/murquise.svg";
import ascher from "../../assets/svgs/dimondShape/ascher.svg";
import trillion from "../../assets/svgs/dimondShape/trillon.svg";
import radient from "../../assets/svgs/dimondShape/radient.svg";
import othershape from "../../assets/svgs/dimondShape/other.svg";

import gallery from "../../assets/svgs/gallary.svg";
import video from "../../assets/svgs/video.svg";
import labReport from "../../assets/svgs/lab-report.svg";

import mesurment from "../../assets/svgs/mes-tret.svg";
import acc_search from "../../assets/svgs/tret-search.svg";
import treatment from "../../assets/svgs/tret_black.svg";
import zoomIcon from '../../assets/svgs/search-zoom-in.svg';
 
import radioAngel from "../../assets/svgs/radio-angel.svg";

import addClose from "../../assets/svgs/save-add.svg";

// support
import chatwith from "../../assets/svgs/support/chatwithus.svg";
import whatsap from "../../assets/svgs/support/whatsap.svg";
import phoneHelp from "../../assets/svgs/support/phone.svg";
import mailHelp from "../../assets/svgs/support/mail.svg";
import weChat from "../../assets/svgs/support/wechat.svg";
import docInfo from "../../assets/svgs/support/docs.svg";
import article from "../../assets/svgs/support/Articles.svg";
import whtasNew from "../../assets/svgs/wtsapp.svg";
import videoInfo from '../../assets/svgs/support/Video.svg';
import eyeView from "../../assets/svgs/eye-view.svg";
import plusIcon from "../../assets/svgs/plus-icon.svg";


// profile
import profilePage from '../../assets/svgs/profile/profile.svg';
import profileBg from '../../assets/svgs/profile/profileBg.png';
import proImg from '../../assets/svgs/profile/proImg.png';

import userProfile from '../../assets/svgs/profile/user.svg';
import likeProfile from '../../assets/svgs/profile/like.svg';
import dislikePro from '../../assets/svgs/profile/dislike.svg';
import editPro from '../../assets/svgs/profile/edit.svg';
import locPro from '../../assets/svgs/profile/loc.svg';
import phonePro from '../../assets/svgs/profile/phone.svg';
import ratingPro from '../../assets/svgs/profile/start.svg';
import addPro from '../../assets/svgs/profile/addPro.svg';


// compare dimond design
import compareDimond from '../../assets/svgs/compare-dimond.svg';
import deleteCom from '../../assets/svgs/deleteCom.svg';


// buy request
import addWhite from '../../assets/svgs/buyRequest/addWhite.svg';
import editBuy from '../../assets/svgs/buyRequest/editBuy.svg';
import shareBuy from '../svgs/buyRequest/shareBuy.svg';


// save search
import deleteSave from '../svgs/saveSearch/delete.svg';
import activate from '../svgs/saveSearch/activate.svg';

// track diamond
import activeIcon from '../svgs/active.svg';
import liveVideo from '../svgs/live-video.svg';
import galleryTrack from '../svgs/hori-img.svg';
import award from '../svgs/award.svg';
import rapaportDOW from '../svgs/rapaport-dow.svg';

// notification
import emailNot from '../svgs/notification/email.svg';

// history 
import download from '../svgs/download-black.svg';
import returnHis from '../svgs/return.svg';

import activePass from '../svgs/active (2).svg';

import copyIcon from '../svgs/copy.svg';
import downloadIcon from '../svgs/download.svg'
// import addWhite from '../svgs/addWhite.svg';

import china from '../svgs/china.svg';
import french from '../svgs/french.svg';

import pro from '../svgs/pro.svg';

import share from '../svgs/share.svg';
import docDw from '../svgs/document-download.svg';
import roundShare from "../svgs/round-share.svg"
import outlineFb from "../svgs/octicon_link-16.svg"
import octLink from "../svgs/ic_outline-facebook.svg"
import vector from "../svgs/Vector.svg"
import icons_edit from "../svgs/akar-icons_edit.svg"
import seller_icons from "../svgs/seller_icons.svg"
import Frame from "../svgs/Frame.svg"

import redArrow from '../svgs/arrow-red.svg';
import greenArrow from '../svgs/arrow-green.svg';

import redIcon from '../svgs/red.svg';
import yellowIcon from '../svgs/yellow.svg';

import morebtn from '../svgs/more.svg';
import editWhite from '../svgs/editwhite.svg';
import shareWhite from '../svgs/shareWhite.svg';
import fbWhite from "../svgs/fbwhite.svg";
import linkWhite from '../svgs/linkWhite.svg';


import pdf from '../svgs/pdf.svg';
import history from '../svgs/history.svg';
import space from '../svgs/space.svg';

const svgs = {
  menuIcon,
  search,
  logo,
  dashboard,
  pair,
  quick,
  searchdash,
  cart,
  watch,
  order,
  recent,
  save,
  logoutdash,
  support,
  bell,
  calc,
  flag,
  dropDownarrow,
  wishlist,
  cartlist,
  mail,
  call,
  skype,
  wtsp,
  flag,
  droparrow,
  navlogo,
  cancel,
  right,
  close,
  terms,
  purchse,
  permission,
  security,
  help,
  settings,
  profile,
  calcDimond,
  exportIcon,
  showEye,
  refreshIcon,
  dollarDimond,
  lockIcon,
  msgBox,
  addIcon,
  sortingIcon,
  shoppingBag,
  cartIcon,
  backBtn,
  filter,
  listView,
  gridView,
  calcDimond,
  location,
  tradePrice,
  gridView,
  saveSearch,
  searchWhite,
  radient,
  trillion,
  ascher,
  murquise,
  emarald,
  rose,
  heart,
  pear,
  princess,
  oval,
  round,
  othershape,
  labReport,
  video,
  gallery,
  treatment,
  acc_search,
  mesurment,
  radioAngel,
  addClose,
  chatwith,
  whtasNew,
  article,
  docInfo,
  weChat,
  mailHelp,
  whatsap,
  phoneHelp,
  videoInfo,
  profilePage,
  profileBg,
  proImg,
  ratingPro,
  phonePro,
  editPro,
  dislikePro,
  likeProfile,
  userProfile,
  addPro,
  compareDimond,
  deleteCom,
  addWhite,
  editBuy,
  shareBuy,
  deleteSave,
  activate,
  eyeView,
  plusIcon,
  activeIcon,
  liveVideo,
  galleryTrack,
  award,
  rapaportDOW,
  emailNot,
  returnHis,
  download,
  activePass,
  copyIcon,
  downloadIcon,
  french,
  china,
  pro,
  share,
  docDw,
  roundShare,
  outlineFb,
  octLink,
  vector,
  icons_edit,
  seller_icons,
  Frame,
  greenArrow,
  redArrow,
  yellowIcon,
  redIcon,
  shareWhite,
  morebtn,
  editWhite,
  fbWhite,
  linkWhite,
  zoomIcon,
  history,
  pdf,
  space
};

export default svgs;
